import React, { useEffect, useState } from "react";
import { StyledPanelContainer } from "../style";
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import {LinkNew} from "../methods";

Chart.register(...registerables);

const meta_url = 'https://meta.maartenpoirot.com/defence/meta.json';

interface MetaDataType {
  defence: Array<number>,
  defence_sum: number,
  party: Array<number>,
  party_sum: number,
  virtual_sum: number,
  thesis_sum: number,
  timestamps: Array<string>,
  count: number,
}

const totalInvites = 97

const DashboardPanel = () => {
  const [jsonData, setJsonData] = useState<MetaDataType | null>(null);

  const getJsonData = async () => {
    const response = await fetch(meta_url);
    const data: MetaDataType = await response.json();
    return data;
  };

  const createPieChart = (canvasId: string, labels: Array<string>, data: Array<number>, title: string) => {
    const ctx = document.getElementById(canvasId) as HTMLCanvasElement;
    if (ctx) {
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          }]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: title,
            }
          }
        }
      });
    }
  };

  const createCumulativeLineChart = (canvasId: string, timestamps: Array<string>, title: string) => {
    const ctx = document.getElementById(canvasId) as HTMLCanvasElement;
    if (ctx) {
      // Sort timestamps in ascending order
      const sortedTimestamps = timestamps.map(ts => new Date(ts)).sort((a, b) => a.getTime() - b.getTime());

      // Create cumulative response data
      const cumulativeData: Array<number> = [];
      const timeLabels: Array<string> = [];
      sortedTimestamps.forEach((timestamp, index) => {
        const formattedTime = timestamp.toISOString().replace('T', ' ').slice(0, 19); // Format as 'YYYY-MM-DD HH:MM:SS'
        timeLabels.push(formattedTime);
        cumulativeData.push(index + 1); // Cumulative count
      });

      cumulativeData[cumulativeData.length - 1] -= 1;

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: timeLabels,
          datasets: [{
            label: 'Cumulative Responses',
            data: cumulativeData,
            borderColor: '#36A2EB',
            fill: false,
          }]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: title,
            }
          },
          scales: {
            x: {
              title: { display: true, text: 'Time' },
              type: 'time',
              time: {
                parser: 'yyyy-MM-dd HH:mm:ss',  // Updated time format for compatibility
                tooltipFormat: 'yyyy-MM-dd HH:mm:ss',  // Updated time format for compatibility
                unit: 'hour',
              }
            },
            y: {
              title: { display: true, text: 'Number of Responses' },
              beginAtZero: true,
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    getJsonData().then(data => {
      setJsonData(data);

      // Create pie charts for attendance
      createPieChart('defencePie', ['Absent', 'Present'], [
        data.defence.filter(x => x === 0).length,
        data.defence.filter(x => x > 0).length,
      ], `Defence Attendance ${data.defence.filter(x => x > 0).length} / ${data.count} (${(data.defence.filter(x => x > 0).length / data.count).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })})`);

      createPieChart('partyPie', ['Absent', 'Present Alone', 'Present +1'], [
        data.party.filter(x => x === 0).length,
        data.party.filter(x => x === 1).length,
        data.party.filter(x => x === 2).length,
      ], `Party Attendance ${data.party_sum}`);

      // Create cumulative line chart for response timings
      createCumulativeLineChart('responseLine', [...data.timestamps, new Date().toISOString()], 'Cumulative Responses Over Time');
    });
  }, []);

  if (jsonData === null) {
    return <p>Loading data...</p>;
  }

  return (
      <StyledPanelContainer>
        <p>Results in this dashboard summarize the responses of this the registration form in real time. If you have any
          thoughts or questions about the dashboard <LinkNew to='/contact' disableIcon={true}>please let me
            know</LinkNew>.</p>

        <h2>Reponse Insights</h2>
        <p>The response rate to the form is high. The total number of registrations received
          is <strong>{jsonData.count} / {totalInvites} (
            {(jsonData.count / totalInvites).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
            )</strong>. <strong>{jsonData.virtual_sum}</strong> people are planning on joining digitally
          and <strong>{jsonData.thesis_sum}</strong> printed theses were requested.</p>
        <p>The absence rate is low. Only <strong>{jsonData.party.filter(x => x === 0).length}</strong> people have
          registered that they cannot make it to the party. The low absense rate
          ({(jsonData.party.filter(x => x === 0).length / jsonData.count).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 0
          })}) is biased because people who cannot make it are more reluctant to reply to the form
          and {totalInvites - jsonData.count} still have to fill out the form.</p>
        <p>{jsonData.party.filter(x => x === 2).length} people will bring a +1. This option is only available to a
          subset of invitees.</p>
        <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
          <canvas id="defencePie" width="300" height="300"></canvas>
          <canvas id="partyPie" width="300" height="300"></canvas>
        </div>

        <h2>Response Timing</h2>
        <p>This chart displays the cumulative number of responses over time. For users who alter their registration,
          their final registration time is used.</p>
        <p>I sent out the initial invitation messages in three batches at 4, 5:30 and 8 pm. I sent a reminder on the
          second day around 4 pm</p>
        <p>This data shows that most responses are directly driven by reminders. Without external factor, people tend to
          reply in the morning at 7 am or evening around 7:30 pm. This information can be taken into account when
          deciding when and how frequent reminders should be scheduled to achieve better response rates.</p>
        <canvas id="responseLine" width="600" height="300"></canvas>
      </StyledPanelContainer>
  );
};

export default DashboardPanel;






