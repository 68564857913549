import React, {useEffect, useRef, useState} from "react";
import {Asterisk, StyledRegistrationForm, StyledPanelContainer} from '../style'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useForm} from "react-hook-form";
import {FaArrowRotateRight} from "react-icons/fa6";
import {ErrorMessage} from "../../../../src/components/form/contact-form.style";
import {storeDataAsCookie} from "../cookies";
import {usePathContext} from "../context";

const AWS_GATEWAY_URL = 'https://39naag43c6.execute-api.eu-west-1.amazonaws.com/default/maartenpoirot-defenceRegistration'

const api_key = process.env.AWS_REGISTRATION_FORM_API_KEY ?? "";

const checkFields = ['defence', 'party', 'thesis', 'partner']

const RegisterForm = () => {
    const {text, debugMode, submitted, setSubmitted, registrationData, setRegistrationData, isEnglish, headlineState, setHeadlineState} = usePathContext();
    const titleReference = useRef<HTMLParagraphElement>(null);
    const [nameState, setNameState] = useState<string>(registrationData.name);
    const [emailState, setEmailState] = useState<string>(registrationData.email);

    const allowPartner = registrationData.hash[0] == '1' || registrationData.hash[0] == '2'

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm();

    // Set initial state values based on the extracted fields
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        // setHeadlineState(text.registerContext);
        // Force an update of the form when submitted by "changing" its content
        // handleFormChange('en', registrationData.en)
        if (submitted) {
            setHeadlineState(text.confirm);
        }
    }, [submitted]);

    const onSubmit = async (data: any) => {
        if (submitted) {
            setSubmitted(false);
            return
        } else {
            setSubmitted(true);
        }

        if (!executeRecaptcha) {
            return
        }
        let msg = ''

        try {
            const recaptchaResult = await executeRecaptcha('defence')
            if (debugMode) {
                handleFormChange('recaptcha', recaptchaResult)
            }
            // Any information that is not captured by the form directly is not part of data!
            data.hash = registrationData.hash;
            data.en = isEnglish;
            data.recaptcha = recaptchaResult;

            if (debugMode) {

                data = {...data,
                  hash: "fno2349",
                  name: "John Doe",
                  email: "maaarteh@gmail.com",
                  en: true,
                  defence: "physical",
                  party: "physical",
                  thesis: "virtual",
                  partner: "physical",
                  // recaptcha: "03AEGFAFcW-fake-1998-ReCaptcha-key-3tf3434f43AFE"
                }

                console.log('hash:', data.hash, "fno2349")
                console.log('name:', data.name, "John Doe")
                console.log('email:', data.email, "maaarteh@gmail.com")
                console.log('en:', data.en, true)
                console.log('defence:', data.defence, 'physical')
                console.log('thesis:', data.thesis, 'virtual')
                console.log('partner:', data.partner, 'physical')
                console.log('recaptcha:', data.recaptcha, 'physical')
            }

            const response = await fetch(AWS_GATEWAY_URL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify(data),
                headers: {
                    'X-Api-Key': api_key,
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });

            const responseCode = response.status
            if (debugMode) {
                console.log('RESPONSE CODE:', responseCode)
            }
            if (responseCode == 200) {
                if (debugMode) {
                    console.log('STORING:', data)
                }
                msg = text.confirm
                storeDataAsCookie(data)
                setSubmitted(true);
            } else {
                msg = `${responseCode} error`
            }
        } catch (error) {
            console.log(error)
            msg = `Can't send message`
        }
        if (titleReference && titleReference.current) {
            setHeadlineState(msg);
        }
        window.scrollTo({top: 100, behavior: "smooth"})
    };

    const handleFormChange = (field: string, option: string | boolean) => {
        setRegistrationData(prevData => ({
            ...prevData,  // Spread the previous state to keep other values intact
            [field]: option  // Dynamically update the field that is being modified
        }));
    };

    const showSubmitError = (msg: {} | string | undefined) => <p className="msg-error">{msg}</p>;

    const confirmationTable = (
        <>
            <table>
                <tbody>
                {['name', 'email'].map((field: string) => (
                    <tr key={`row-${field}`}>
                        <td>{text[field].label}<Asterisk error={false}/></td>
                        <td>{registrationData[field]}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <table>
                <tbody>
                {checkFields.map((field: string) => (
                    <tr key={`row-${field}`} style={{display: !allowPartner && field == 'partner' ? 'none' : 'table-row'}}>
                        <td>{text[field].confirm}</td>
                        <td>{text[field][registrationData[field]]}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );

    const registrationTable = (
        <>
            <table>
                <tbody>

                <tr>
                    <td>{text.name.label}{<Asterisk error={'name' in errors}/>}</td>
                    {/*{errors.name ? <ErrorMessage>{errors.name.message}</ErrorMessage> : Placeholder}*/}
                    <td className='wide-column'>
                        <input
                            {...register("name", {
                                required: `${text.name.label} is ${text.required}`,
                                maxLength: {value: 200, message: "Your name cannot exceed 200 characters"},
                            })}
                            type="text"
                            name="name"
                            id="name"
                            value={nameState}
                            placeholder={registrationData.name ? registrationData.name : text.name.placeholder}
                            disabled={isSubmitting}
                            onChange={(e) => setNameState(e.target.value)}
                            onBlur={(e) => handleFormChange('name', e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>{text.email.label}{<Asterisk error={'email' in errors}/>}</td>
                    {/*{errors.email ? <ErrorMessage>{errors.email.message}</ErrorMessage> : Placeholder}*/}
                    <td>
                        <input
                            {...register("email", {
                                required: `${text.email.label} is ${text.required}`,
                            })}
                            type="email"
                            name="email"
                            id="email"
                            value={emailState}
                            placeholder={text.email.placeholder}
                            disabled={isSubmitting}
                            onChange={(e) => setEmailState(e.target.value)}
                            onBlur={(e) => handleFormChange('email', e.target.value)}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                {checkFields.map((field) => (
                    <tr key={`row-${field}`}
                        style={{display: !allowPartner && field == 'partner' ? 'none' : 'table-row'}}>
                        <td>{text[field].label}</td>
                        <td>
                            <select
                                {...register(field, {required: `Please select an option for ${field}`})}
                                name={field}
                                id={field}
                                value={registrationData[field]}
                                onChange={(e) => handleFormChange(field, e.target.value)}
                                disabled={isSubmitting}
                            >
                                {['physical', 'virtual', 'none']
                                    .filter((option) => text[field][option]) // Ensure the option exists in the text object
                                    .map((option) => {
                                        return (
                                            <option
                                                key={`${field}-${option}`}
                                                value={option}
                                            >
                                                {text[field][option]}
                                            </option>
                                        );
                                    })}
                            </select>
                        </td>
                        {errors[field] && <ErrorMessage>{errors[field]?.message}</ErrorMessage>}
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )

    return (
        <StyledPanelContainer>
            {debugMode && <>
                        <div className='debug'>
                            <pre>{JSON.stringify(registrationData, null, 2)}</pre>
                        </div>
                    </>}
            <div>
                <p dangerouslySetInnerHTML={{__html: text.common + " " + headlineState}}></p>
            </div>
            {errors && errors.submit && showSubmitError(errors.submit.message)}
            <StyledRegistrationForm onReset={onSubmit} onSubmit={handleSubmit(onSubmit)} method="post">
                {submitted ? (
                    confirmationTable
                ) : (
                    registrationTable
                )}
                <div className="button-container">
                    <button type={submitted ? 'reset' : 'submit'} disabled={isSubmitting}>
                        {isSubmitting ? (
                            text.submittingButton
                        ) : ( submitted ? (
                            <>{text.redo} <FaArrowRotateRight/></>
                        ) : (
                            text.submitButton
                        ))}
                    </button>
                </div>
            </StyledRegistrationForm>
        </StyledPanelContainer>
    );
};

export default RegisterForm;
