import RegisterForm from "./panels/registerPanel";
import InvitePanel from "./panels/invitePanel";
import React from "react";
import {ContentContainer, StyledHeader} from "./style";
import {LinkNew} from "./methods";
import {FaWhatsapp} from "react-icons/fa6";
import {GB, NL} from "country-flag-icons/react/3x2";
import {activePanel} from "./interfaces";
import { usePathContext} from "./context";
import DashboardPanel from "./panels/dashboardPanel";

const DefenceContent: React.FC = () => {
    const { isEnglish, toggleEnglish, panelState, setPanelState, text} = usePathContext();

    // const panelStates: activePanel[] = ['register', 'dashboard', 'invite'];
    const panelStates: activePanel[] = ['register', 'invite'];

    // Header Component
    const Header = () => (
        <StyledHeader columnCount={panelStates.length}>
            <div onClick={toggleEnglish} className="no-border flag">
                <span>
                    <LinkNew to="https://api.whatsapp.com/send?phone=31631515577" disableIcon={true}>
                        <FaWhatsapp />
                    </LinkNew>
                </span>
                <span>{isEnglish ? <NL /> : <GB />}</span>
            </div>
            <div>
                {panelStates.map((state) => (
                    <div
                        key={state}
                        onClick={() => setPanelState(state)}
                        className={panelState === state ? 'active' : ''}
                    >
                        <h3>{text[state]?.label || state}</h3>
                    </div>
                ))}
            </div>
        </StyledHeader>
    );

    // PanelContent Component
    const PanelContent = () => {
        switch (panelState) {
            case 'register':
                return <RegisterForm/>;
            case 'invite':
                return <InvitePanel/>;
            case 'dashboard':
                return <DashboardPanel/>;
            default:
                return null;
        }
    };

    // Main Component Return
    return (
        <ContentContainer>
            <Header />
            <PanelContent />
        </ContentContainer>
    );
};

export default DefenceContent;

