import multiLanguageText from '../text-data/structure'
import TextType from "../text-data/interface";
import {activePanel, emptyPayload, PageContextType, payloadType} from "./interfaces";
import React, {
    Dispatch,
    SetStateAction,
    useRef,
    useState,
    createContext,
    useContext, useEffect,
} from "react";
import {DefenceStateProviderProps} from "./interfaces";
import {generateHash} from "./methods";
import {useLocation} from "react-router-dom";
import {doesCookieExist, getDataFromCookie} from "./cookies";
import {fetchUserData} from "./aws-interactions";


const PathContext = createContext<{
    isEnglish: boolean;
    setIsEnglish: Dispatch<SetStateAction<boolean>>;
    panelState: activePanel;
    toggleEnglish: () => void;
    setPanelState: Dispatch<SetStateAction<activePanel>>;
    text: TextType;
    debugMode: boolean;
    pageContext: PageContextType;
    submitted: boolean;
    setSubmitted: Dispatch<SetStateAction<boolean>>;
    registrationData: payloadType,
    setRegistrationData: Dispatch<SetStateAction<payloadType>>;
    headlineState: string;
    setHeadlineState: Dispatch<SetStateAction<string>>;
}>({
    isEnglish: false,
    setIsEnglish: () => {},
    panelState: 'invite',
    toggleEnglish: () => {},
    setPanelState: () => {}, // This line is valid because it technically accepts any input
    text: {} as TextType, // Using a type assertion for initialization
    debugMode: false,
    pageContext: {
        preview: '',
        defence: undefined,
        party: undefined,
    },
    submitted: false,
    setSubmitted: () => {},
    registrationData: emptyPayload,
    setRegistrationData: () => {},
    headlineState: '',
    setHeadlineState: () => {},
});



export const usePathContext = () => {
    const context = useContext(PathContext);
    if (!context) {
        throw new Error("usePathContext must be used within a PathProvider");
    }
    return context;
};


function useQuery() {
    let urlQuery = ''
    if (typeof document !== 'undefined') {
        const {search} = useLocation();
        urlQuery = search
    }
    return React.useMemo(() => new URLSearchParams(urlQuery), [urlQuery]);
}


export const DefenceStateProvider: React.FC<DefenceStateProviderProps> = ({ children, debugMode, pageContext }) => {
    const [isEnglish, setIsEnglish] = useState<boolean>(false);
    const [panelState, setPanelState] = useState<activePanel>('invite');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [registrationData, setRegistrationData] = useState<payloadType>(emptyPayload);
    const query = useQuery().get("h") || generateHash();

      // Use useEffect to run the logic only once, on first load
    useEffect(() => {
        if (typeof document !== "undefined") {
            if (doesCookieExist()) {
                const cookieData = getDataFromCookie();
                if (cookieData !== null) {
                    setRegistrationData(cookieData);
                    setIsEnglish(cookieData.en)
                    setSubmitted(true);
                }
            } else {
                const fetchAndSetUserData = async () => {
                    if (query[0] != '0') {
                        const userData = await fetchUserData(query);

                        if (userData !== undefined) {
                            setRegistrationData({
                                ...emptyPayload,
                                hash: query,
                                name: userData.name,
                                email: userData.email,
                                en: userData.en
                            });
                            setIsEnglish(userData.en)
                        }
                    } else {
                        setRegistrationData({
                            ...emptyPayload,
                            hash: query,
                        })
                    }
                };
                fetchAndSetUserData().then(); // Call the async function
            }
        }
    }, []);



    // Function to toggle the language between English and Dutch
    const toggleEnglish = () => {
        setIsEnglish(prevState => !prevState);
    };

    // Select the text based on the current language state
    const text: TextType = isEnglish ? multiLanguageText.en : multiLanguageText.nl;
    const [headlineState, setHeadlineState] = useState<string>(text.registerContext);

    useEffect(() => {
        if (submitted) {
            setHeadlineState(text.confirm);
        } else {
            setHeadlineState(text.registerContext);
        }

    }, [isEnglish]);

    return (
        <PathContext.Provider
            value={{
                isEnglish,
                setIsEnglish,
                panelState,
                toggleEnglish,
                setPanelState,
                text,
                debugMode,
                pageContext,
                submitted,
                setSubmitted,
                registrationData,
                setRegistrationData,
                headlineState,
                setHeadlineState
            }}
        >
            {children}
        </PathContext.Provider>
    );
};

export default DefenceStateProvider;



